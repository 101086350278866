import { BaseElement } from '../../01-base/BaseElement';
import { getCookie, setCookie } from '../../01-base/util/Cookies';

class CookiesBannerComponent extends BaseElement {
    properties() {
        return {
            trackingCookies: false,
            editMode: false,
            area: '',
            trackingNames: [
                '_et_coid',
                'isSdEnabled',
                'BT_ctst',
                'BT_sdc',
                'BT_pdc',
                'BT_ecl',
                'cntcookie',
                'GS3_v',
                'DC_SESSID',
                'BT_lg',
                'BT_cm',
                'BT_echk',
            ],
        };
    }

    hooks() {
        return {
            connected: () => {
                this._cookiesArea = document.getElementById('cookies');
                if (this._cookiesArea && !this.editMode && !getCookie('cookiesBannerShown')) {
                    this._cookiesArea.classList.remove('d-none');
                }
                if (window._etracker) {
                    this.trackingCookies = window._etracker.areCookiesEnabled();
                }
                this.$refs.trackingCookies.checked = this.trackingCookies;
                this.removeAllCookies();

                if (this.area === 'content-area' && !this.editMode) {
                    this.$refs.close.classList.add('d-none');
                }
            },
        };
    }

    events() {
        return {
            '#trackingCookies': {
                change: e => {
                    this.trackingCookies = e.target.checked;
                },
            },
            '[data-submit]': {
                click: () => {
                    if (!this.editMode) {
                        if (window._etracker) {
                            this.trackingCookies
                                ? window._etracker.enableCookies(window.location.host)
                                : window._etracker.disableCookies(window.location.host);
                        }

                        this._cookiesArea.classList.add('d-none');
                        setCookie('cookiesBannerShown', 'true', 365);
                    }
                },
            },
            '[data-allowAll]': {
                click: () => {
                    this.trackingCookies = document.getElementById('trackingCookies').checked = true;
                    if (!this.editMode) {
                        if (window._etracker) {
                            this.trackingCookies
                                ? window._etracker.enableCookies(window.location.host)
                                : window._etracker.disableCookies(window.location.host);
                        }
                        this._cookiesArea.classList.add('d-none');
                        setCookie('cookiesBannerShown', 'true', 365);
                    }
                },
            },
        };
    }

    removeAllCookies() {
        if (!this.trackingCookies) {
            this.trackingNames.forEach(name => {
                if (getCookie(name)) {
                    setCookie(name, 'false', -1);
                    const t = getCookie(name);
                }
            });
        }
    }
}

customElements.define('cookies-banner-component', CookiesBannerComponent);
